<template>
  <div class="app-container">
    <div style="width: 70%; margin: 20px auto">
      <el-steps :active="1">
        <el-step title="填写企业信息" description=""></el-step>
        <el-step title="填写申请信息" description=""></el-step>
        <el-step title="付款" description=""></el-step>
        <el-step title="开立保函" description=""></el-step>
      </el-steps>
    </div>
    <div class="applyFor">
      <el-form
        style="background: #fff"
        ref="form"
        size="small"
        label-position="top"
        :model="formModel"
        v-loading="contentLoading"
        class="demo-ruleForm"
      >
        <div
          style="
            line-height: 40px;
            margin-bottom: 20px;
            border-bottom: 1px solid #eee;
            font-weight: 700;
          "
        >
          投标企业信息
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="企业名称"
              prop="etpName"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input
                size="small"
                v-model="formModel.etpName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="社会信用代码"
              prop="etpUscc"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input
                size="small"
                v-model="formModel.etpUscc"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="企业性质"
              prop="etpProperty"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <maindata
                v-model="formModel.etpProperty"
                dataKey="company-property"
                dataType="singleSelect"
              >
              </maindata>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="所属行业"
              prop="etpIndustry"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <maindata
                v-model="formModel.etpIndustry"
                dataKey="company-industry"
                dataType="tree"
              >
              </maindata>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="注册资本（万元）"
              prop="etpRegCapital"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input
                size="small"
                v-model="formModel.etpRegCapital"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="证件签发日期"
              prop="etpCertEndDate"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <el-date-picker
                style="width: 48%"
                v-model="formModel.etpCertBeginDate"
                type="date"
                placeholder="选择开始日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <el-date-picker
                style="float: right; width: 48%"
                v-model="formModel.etpCertEndDate"
                type="date"
                placeholder="选择结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="注册地址"
              prop="etpArea"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <addressPage
                :etpRegAddress="etpRA2"
                @addr-sent="initAddr2"
              ></addressPage>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="营业执照"
              prop="etpIdcard"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <uploadBtn
                v-model="formModel.etpIdcard"
                :limit="1"
                @syncFileIds="setEtpIdcard2"
              >
              </uploadBtn>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          :gutter="20"
          style="
            border-top: 1px solid #eee;
            font-weight: 700;
            padding-top: 20px;
            margin-top: 20px;
          "
        >
          <el-col :span="12">
            <el-form-item
              label="上年度资产规模(万元)"
              prop="etpLastAssets"
             
            >
              <el-input
                v-model="formModel.etpLastAssets"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="上年度营业收入(万元)"
              prop="etpLastIncome"
             
            >
              <el-input
                v-model="formModel.etpLastIncome"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="经办人"
              prop="operator"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input v-model="formModel.operator" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="经办人手机"
              prop="operatorPhone"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input
                maxlength="11"
                v-model="formModel.operatorPhone"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
           <el-col :span="12">
            <el-form-item
              label="收件人"
              prop="guaranteePostLxr"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <el-input v-model="formModel.guaranteePostLxr" placeholder="请输入" />
            </el-form-item>
          </el-col>
           <el-col :span="12">
            <el-form-item
              label="收件人电话"
              prop="guaranteePostLxrTelephone"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <el-input
                maxlength="11"
                v-model="formModel.guaranteePostLxrTelephone"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="保函邮寄地址"
              prop="guaranteePostAddr"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <addressPage
                :etpRegAddress="etpRA"
                @addr-sent="initAddr"
              ></addressPage>
            </el-form-item>
          </el-col>
        </el-row>
        <div
          style="
            border-bottom: 1px solid #eee;
            font-weight: 700;
            margin-bottom: 10px;
            padding: 10px 0;
          "
        >
          项目信息
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="项目名称"
              prop="projectName"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input v-model="formModel.projectName" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="项目编号"
              prop="projectCode"
             
            >
              <el-input v-model="formModel.projectCode" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="投标截止日期"
              prop="bidEndDate"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <el-date-picker
                style="width: 100%"
                v-model="formModel.bidEndDate"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="担保金额（人民币）："
              prop="guaranteeMoney"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input
                oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                v-model="formModel.guaranteeMoney"
                placeholder="元"
                @input="guaranteeMoneyBlur"
                @blur="guaranteeMoneyBlur"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="招标单位（请务必与采购文件中招标方信息保持一致，否则可能导致投标被拒保）"
              prop="bidCompanyName"
              :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
            >
              <el-input
                v-model="formModel.bidCompanyName"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="保函有效期(需根据招标文件或甲方的要求计算后填写，若有问题可联系客服人员)"
              prop="guaranteeDays"
              :rules="[
                {
                  required: true,
                  message: '保函有效期不能为空',
                  trigger: 'blur'
                }
              ]"
            >
              <div style="display: flex; align-items: center; height: 35px">
                <el-radio-group
                  @input="ridioBtn"
                  v-model="radio"
                  style="flex: 1"
                >
                  <el-radio :label="60">60天</el-radio>
                  <el-radio :label="90">90天</el-radio>
                  <el-radio :label="120">120天</el-radio>
                  <el-radio :label="180">180天</el-radio>
                  <el-radio :label="0">自定义</el-radio>
                </el-radio-group>
                <template v-if="radio == 0">
                  <el-input
                    style="width: 150px"
                    onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                    v-model="formModel.guaranteeDays"
                    placeholder="请输入"
                  />
                  <span style="padding-left: 5px">天</span>
                </template>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="选择保函文本（选择后点击图标预览）"
              prop="guaranteeDocument"
              :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
            >
              <maindata
                v-model="formModel.guaranteeDocument"
                dataKey="guarantee-document"
                dataType="singleSelect"
              >
              </maindata>
              <div
                v-if="formModel.guaranteeDocument == '01'"
                style="
                  text-align: left;
                  padding-top: 20px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                "
                @click="pdfBtn"
              >
                <img
                  class="yulan_img"
                  src="../../../assets/img/Pdf.png"
                  alt=""
                />
                <span
                  style="font-size: 14px; padding-left: 15px; color: #3d8bfc"
                  >保函文本（通用）点击预览</span
                >
              </div>
              <div
                v-if="formModel.guaranteeDocument == '02'"
                style="
                  text-align: left;
                  padding-top: 20px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                "
                @click="pdfBtn"
              >
                <img
                  class="yulan_img"
                  src="../../../assets/img/Pdf.png"
                  alt=""
                />
                <span
                  style="font-size: 14px; padding-left: 15px; color: #3d8bfc"
                  >保函文本（独立）点击预览</span
                >
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="招标方发布的招标(采购)文件"
              prop="bidDocument"
              :rules="[{ required: true, message: '请上传', trigger: 'blur' }]"
            >
              <uploadBtn
                v-model="formModel.bidDocument"
                @syncFileIds="setEtpIdcard"
                :accept="bidFileType"
              >
              </uploadBtn>
            </el-form-item>
          </el-col>
          <el-col v-if="formModel.guaranteeDocument == '03'" :span="12">
            <el-form-item
              label="请上传自定义保函文本"
              prop="guaranteeDocumentExt"
              :rules="[{ required: true, message: '请上传', trigger: 'blur' }]"
            >
              <uploadBtn
                v-model="formModel.guaranteeDocumentExt"
                @syncFileIds="setEtpIdcard3"
                :accept="bidFileType3"
              >
              </uploadBtn>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="我要给客服留言" prop="remark">
              <el-input v-model="formModel.remark" placeholder="请输入" />
            </el-form-item>
          </el-col>
        </el-row>
        <div
          style="padding: 20px; text-align: center; border-top: 1px solid #eee"
        >
          <template v-if="formModel.guaranteeMoney">
            <div style="font-size: 24px">
              当下操作预计保函手续费为{{
                isRmbMsg
              }}元，若有特殊情况客服会与您取得联系
            </div>
            <div style="font-size: 20px; padding: 10px 0">
              提交即表示同意
              <span
                style="color: #3d8bfc; cursor: pointer"
                @click="goUserAgreement"
                >《线上保函协议》</span
              >
            </div>
            <div style="font-size: 18px; padding-bottom: 20px">
              有问题请拨打:40088958895
            </div>
          </template>
          <el-button
            style="width: 500px; height: 40px; font-size: 20px"
            size="small"
            type="primary"
            @click="submitForm('form')"
            >提交</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import uploadBtn from '@/components/uploadBtn/index.vue'
import { parseTime } from '@/utils/DateUtils'
import {
  getDetailC,
  getApplyZtb,
  getDetailZtb,
  getUpdateZtb
} from '@/api/system/applyFor'
import addressPage from '@/components/MainData/mainDataAddr.vue'
import maindata from '@/components/MainData/index.vue'
export default {
  components: { maindata, addressPage, uploadBtn },
  data() {
    return {
      userId: '',
      radio: null,
      bidFileType3: '.doc,.docx,.pdf',
      isPage: 1,
      bidFileType: '.doc,.docx,.zip,.rar,.pdf',
      companyInfo: {},
      upload: {
        limit: 1,
        viewImage: true
      },
      contentLoading: false,
      guaranteeId: '',
      formModel: {
        // 企业信息
        etpUserId: '',
        etpName: '', // 企业名称
        etpUscc: '', // 社会信用代码
        etpProperty: '', // 企业性质
        etpIndustry: '', // 所属行业
        etpRegCapital: '', // 注册资本
        etpCertBeginDate: '', // 证件开始日期
        etpCertEndDate: '', // 证件结束日期
        etpIdcard: '', // 营业执照
        etpProvince: '', // 注册省
        etpCity: '', // 注册市
        etpArea: '', // 注册区/县
        etpStreet: '', // 注册街道
        etpAddr: '', // 注册详细
        // 项目信息
        guaranteeId: '',
        guaranteeCategory: '01', // 保函类型 01:投标保函;02:履约保函;03:质量保函;04:预付款保函
        guaranteeType: 0, //0:银行保函 1：商业保函 2：保险保函
        returnGuaranteeOrg: 'ccb',
        bidCompanyName: '', // 招标企业名称
        bidContract: '', // 合作协议(合作合同，自动生成合同编号)
        bidDocument: '', // 招标文件
        bidEndDate: '', // 招标-结束日期
        etpLastAssets: '', // 企业上年度资产规模
        etpLastIncome: '', // 申请企业上年度营业收入
        guaranteeDocument: '', // 保函文本（通用/独立/自定义
        guaranteeDocumentExt: '', // 自定义保函文本上传的id
        guaranteeMoney: '', // 保函金额
        guaranteeDays: null, // 有效期天数
        guaranteePayMoney: '', // 支付金额
        guaranteePostProvince: '', // 保函邮寄地址所在省/直辖市
        guaranteePostCity: '', // 保函邮寄地址所在市
        guaranteePostArea: '', // 保函邮寄地址所在区县
        guaranteePostAddr: '', // 保函邮寄地址所在镇
        guaranteePostStreet: '', // 保函邮寄地址所在街道/镇
        operator: '', // 经办人
        operatorPhone: '', // 经办人手机号
        projectCode: '', // 项目编码
        projectName: '' // 项目名称
      },
      etpRA: {
        province: '',
        city: '',
        area: '',
        addr: '',
        street: ''
      },
      etpRA2: {
        province: '',
        city: '',
        area: '',
        addr: '',
        street: ''
      }, // 注册地址
      isRmbMsg: 0
    }
  },
  mounted() {
    this.formModel.etpUserId = this.$route.query.userId
    this.guaranteeId = this.$route.query.guaranteeId
    this.formModel.guaranteeId = this.$route.query.guaranteeId
    if (this.formModel.guaranteeId) {
      this.getDetailData()
    } else {
      this.getComanyInfo()
    }
  },
  methods: {
    ridioBtn() {
      // 选择天数
      if (
        this.radio == 60 ||
        this.radio == 90 ||
        this.radio == 120 ||
        this.radio == 180
      ) {
        this.formModel.guaranteeDays = this.radio
      } else {
        this.formModel.guaranteeDays = ''
        this.formModel.guaranteeDays = Number(this.formModel.guaranteeDays)
      }
    },
    guaranteeMoneyBlur() {
      // 担保金额失去焦点事件
      this.isRmbMsg = (this.formModel.guaranteeMoney / 100).toFixed(2)
      if (this.isRmbMsg < 500) {
        this.isRmbMsg = 500
      }
    },
    getComanyInfo() {
      // 获取公司信息
      this.contentLoading = true
      getDetailC({ userId: this.formModel.etpUserId }).then((res) => {
        this.contentLoading = false
        if (res.data.success) {
          this.formModel.etpName = res.data.result.etpName
          this.formModel.etpProperty = res.data.result.etpProperty
          this.formModel.etpUscc = res.data.result.etpUscc
          this.formModel.etpIndustry = res.data.result.etpIndustry
          this.formModel.etpRegCapital = res.data.result.etpRegCapital
          this.formModel.etpCertBeginDate = res.data.result.etpCertBeginDate
          this.formModel.etpCertEndDate = res.data.result.etpCertEndDate
          this.formModel.etpIdcard = res.data.result.etpIdcard
          this.formModel.etpProvince = res.data.result.etpProvince
          this.formModel.etpCity = res.data.result.etpCity
          this.formModel.etpArea = res.data.result.etpArea
          this.formModel.etpStreet = res.data.result.etpStreet
          this.formModel.etpAddr = res.data.result.etpAddr
          this.formModel.etpCertBeginDate = parseTime(
            this.formModel.etpCertBeginDate,
            '{y}-{m}-{d}'
          )
          this.formModel.etpCertEndDate = parseTime(
            this.formModel.etpCertEndDate,
            '{y}-{m}-{d}'
          )
          this.etpRA2 = {
            province: this.formModel.etpProvince,
            city: this.formModel.etpCity,
            area: this.formModel.etpArea,
            street: this.formModel.etpStreet,
            addr: this.formModel.etpAddr
          }
        } else {
          this.$message.error(res.data.message)
        }
        this.contentLoading = false
      })
    },
    pdfBtn() {
      if (this.formModel.guaranteeDocument === '01') {
        window.open(
          'https://api.yinbaobiao.com/web/ybb/upload/document-doc/tb-common.pdf',
          '_blank'
        )
      } else if (this.formModel.guaranteeDocument === '02') {
        window.open(
          'https://api.yinbaobiao.com/web/ybb/upload/document-doc/tb-alone-guarantee.pdf',
          '_blank'
        )
      }
    },
    initAddr2(originAddr) {
      // 企业地址
      this.formModel.etpProvince = originAddr.province
      this.formModel.etpCity = originAddr.city
      this.formModel.etpArea = originAddr.area
      this.formModel.etpStreet = originAddr.street
      this.formModel.etpAddr = originAddr.addr
    },
    initAddr(originAddr) {
      // 项目地址
      this.formModel.guaranteePostProvince = originAddr.province
      this.formModel.guaranteePostCity = originAddr.city
      this.formModel.guaranteePostArea = originAddr.area
      this.formModel.guaranteePostAddr = originAddr.addr
      this.formModel.guaranteePostStreet = originAddr.street
    },
    sysncCompanyIdcard(v) {
      this.formModel.companyIdcard = v
    },
    submitForm(formName) {
      if (typeof this.formModel.etpCertBeginDate === 'string') {
        this.formModel.etpCertBeginDate = Date.parse(
          this.formModel.etpCertBeginDate
        )
        this.formModel.etpCertEndDate = Date.parse(
          this.formModel.etpCertEndDate
        )
      }
      if (
        typeof this.formModel.bidEndDate === 'string' ||
        typeof this.formModel.bidEndDate === 'object'
      ) {
        this.formModel.bidEndDate = Date.parse(this.formModel.bidEndDate)
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let apiUrl = ''
          if (this.guaranteeId) {
            // 更新
            console.log('更新')
            apiUrl = getUpdateZtb
          } else {
            // 新增
            console.log('新增')
            apiUrl = getApplyZtb
          }
          apiUrl(this.formModel).then((res) => {
            if (res.data.success) {
              let guaranteeId = ''
              console.log(res, 'resresres')
              if (this.guaranteeId) {
                guaranteeId = this.guaranteeId
              } else {
                guaranteeId = res.data.result.guaranteeId
              }

              this.$message.success(res.data.message)
              this.$router.push({
                path: '/system/applyFor/payment',
                query: {
                  type: 1,
                  guaranteeId: guaranteeId
                }
              })
            } else {
              console.log(res.data.message)
              this.$message.error(res.data.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getDetailData() {
      // 获取详情
      this.contentLoading = true
      const data = {
        guaranteeId: this.formModel.guaranteeId
      }
      getDetailZtb(data).then((res) => {
        this.contentLoading = false
        if (res.data.success) {
          this.formModel = res.data.result
          if (
            res.data.result.guaranteeDays == 60 ||
            res.data.result.guaranteeDays == 90 ||
            res.data.result.guaranteeDays == 120 ||
            res.data.result.guaranteeDays == 180
          ) {
            this.radio = res.data.result.guaranteeDays
          } else {
            this.radio = 0
          }

          console.log(this.formModel.guaranteeDays, 'this.formModel.radio')
          this.formModel.guaranteeId = this.guaranteeId
          this.formModel.etpCertBeginDate = parseTime(
            this.formModel.etpCertBeginDate,
            '{y}-{m}-{d}'
          )
          this.formModel.etpCertEndDate = parseTime(
            this.formModel.etpCertEndDate,
            '{y}-{m}-{d}'
          )
          this.formModel.bidEndDate = parseTime(this.formModel.bidEndDate)
          this.etpRA = {
            province: this.formModel.guaranteePostProvince,
            city: this.formModel.guaranteePostCity,
            area: this.formModel.guaranteePostArea,
            addr: this.formModel.guaranteePostAddr,
            street: this.formModel.guaranteePostStreet
          }
          this.etpRA2 = {
            province: this.formModel.etpProvince,
            city: this.formModel.etpCity,
            area: this.formModel.etpArea,
            addr: this.formModel.etpAddr,
            street: this.formModel.etpStreet
          }
          this.guaranteeMoneyBlur()
        } else {
          this.$message.error(res.data.message)
        }
        this.contentLoading = false
      })
    },
    setEtpIdcard(v) {
      // 文件上传
      this.formModel.bidDocument = v
    },
    setEtpIdcard2(v) {
      // 营业执照上传
      this.formModel.etpIdcard = v
    },
    setEtpIdcard3(v) {
      this.formModel.guaranteeDocumentExt = v
    },
    goUserAgreement() {
      // 用户协议
      window.open('/privacyAgreement', '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.applyFor {
  width: 1200px;
  margin: 0 auto;
}

::v-deep .el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0 0;
}

.edit_box {
  cursor: pointer;
  color: #3d8bfc;
  float: right;
  font-weight: normal;
  font-size: 14px;
}

.yulan_img {
  width: 50px;
  height: 50px;
}
</style>
