<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--用户数据-->
      <el-col>
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          v-show="showSearch"
          label-width="100px"
        >
          <el-form-item label="用户登录名" prop="loginName">
            <el-input
              v-model="queryParams.loginName"
              placeholder="请输入用户登录名"
              clearable
              size="small"
              style="width: 240px"
            />
          </el-form-item>
          <el-form-item label="所属企业" prop="enterpriseSecret">
            <el-input
              v-model="queryParams.enterpriseSecret"
              placeholder="请输入企业标识"
              clearable
            />
          </el-form-item>
          <el-form-item label="手机号码" prop="phone">
            <el-input
              v-model="queryParams.phone"
              placeholder="请输入手机号码"
              clearable
              size="small"
              style="width: 240px"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              v-preventReClick
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              v-preventReClick
              @click="handleAdd"
              >新增</el-button
            >
          </el-col>
          <!--<el-col :span="1.5">
            <el-button
              type="success"
              plain
              icon="el-icon-edit"
              size="mini"
              :disabled="single"
              @click="handleUpdate"
              >修改</el-button
            >
          </el-col>
          <el-col :span="1.5">
            <el-button
              type="danger"
              plain
              icon="el-icon-delete"
              size="mini"
              :disabled="multiple"
              @click="handleDelete"
              >删除</el-button
            >
          </el-col>
          <el-col :span="1.5">
            <el-button
              type="info"
              plain
              icon="el-icon-upload2"
              size="mini"
              v-preventReClick
              @click="handleImport"
              v-hasPermi="['system:user:import']"
              >导入</el-button
            >
          </el-col>
          <el-col :span="1.5">
            <el-button
              type="warning"
              plain
              icon="el-icon-download"
              size="mini"
              v-preventReClick
              @click="handleExport"
              >导出</el-button
            >
          </el-col>-->
          <right-toolbar
            :showSearch.sync="showSearch"
            @queryTable="getList"
            :columns="columns"
          ></right-toolbar>
        </el-row>
        <el-table
          v-loading="loading"
          :data="userList"
          size="mini"
          fit
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            label="用户GUID"
            header-align="left"
            align="left"
            key="guid"
            prop="guid"
            width="220"
          />
          <!-- <el-table-column
            label="用户编号"
            align="center"
            key="personId"
            prop="personId"
            width="220"
          /> -->
          <el-table-column
            label="用户登录名称"
            header-align="left"
            align="left"
            key="loginName"
            prop="loginName"
          />
          <el-table-column
            label="用户名字全称"
            key="name"
            prop="name"
            header-align="left"
            align="left"
          />
          <el-table-column
            label="企业标识"
            key="enterpriseSecret"
            prop="enterpriseSecret"
            header-align="left"
            align="left"
          />
          <el-table-column
            label="Email"
            header-align="left"
            align="left"
            key="email"
            prop="email"
            width="120"
          />
          <el-table-column
            label="状态"
            header-align="center"
            align="center"
            width="120"
            key="statustxt"
            prop="statustxt"
          />
          <el-table-column
            label="创建时间"
            header-align="right"
            align="right"
            prop="created"
            width="160"
          >
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            width="160"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-preventReClick
                @click="handleUpdate(scope.row)"
                >修改</el-button
              >
              <el-button
                v-if="scope.row.uGuid !== 1"
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-preventReClick
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-preventReClick
                @click="handleAuthRole(scope.row)"
                >分配角色</el-button
              >
              <!--<el-button
                size="mini"
                type="text"
                icon="el-icon-key"
                @click="handleResetPwd(scope.row)"

                >重置</el-button
              >-->
            </template>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.currentPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-col>
    </el-row>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row>
          <el-col>
            <el-form-item label="姓名" prop="name">
              <el-input v-model="form.name" placeholder="请输入用户名字全称" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属企业标识" prop="enterpriseSecret">
              <el-input
                v-model="form.enterpriseSecret"
                placeholder="请输入企业标识"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属部门" prop="orgGuid">
              <treeselect
                v-loading="orgTreeLoading"
                v-model="form.orgGuid"
                :options="deptOptions"
                :normalizer="normalizer"
                :show-count="true"
                @open="openOrgTree"
                placeholder="请选择所属部门"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号码" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入手机号码"
                maxlength="11"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="email">
              <el-input
                v-model="form.email"
                placeholder="请输入邮箱"
                maxlength="50"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="登录名" prop="loginName">
              <el-input v-model="form.loginName" placeholder="请输入登录名" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户密码" prop="loginPwd">
              <el-input
                v-model="form.loginPwd"
                placeholder="请输入用户密码"
                type="password"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="用户性别" prop="sex">
              <el-select v-model="form.sex" placeholder="请选择">
                <el-option
                  v-for="dict in sexOptions"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态" prop="status">
              <el-radio-group v-model="form.status">
                <el-radio
                  v-for="dict in statusOptions"
                  :key="dict.value"
                  :label="dict.value"
                  >{{ dict.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="生效日期" prop="userEffectiveTime">
              <el-date-picker
                v-model="form.userEffectiveTime"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="失效日期" prop="userExpireTime">
              <el-date-picker
                v-model="form.userExpireTime"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="个人描述">
              <el-input
                v-model="form.info"
                type="textarea"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" v-preventReClick
          >确 定</el-button
        >
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 角色 -->
    <el-dialog
      title="分配角色"
      :visible.sync="roleOpen"
      width="800px"
      append-to-body
    >
      <el-form ref="roleForm" :model="queryRoleParams" label-width="80px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="角色名称" prop="name">
              <el-input v-model="queryRoleParams.name" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所属企业" prop="enterpriseSecret">
              <el-input
                v-model="queryRoleParams.enterpriseSecret"
                placeholder="请输入企业标识"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所属系统" prop="appId">
              <el-input
                v-model="queryRoleParams.appId"
                placeholder="请输入AppId"
                clearable
                size="small"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-search"
                size="mini"
                v-preventReClick
                @click="handleRoleQuery"
                >搜索</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        v-loading="loading"
        :data="roleList"
        ref="roleTable"
        @select="selectRoleRowChange"
        @select-all="handleRoleSelectionAll"
      >
        <el-table-column label="序号" type="index" align="center">
        </el-table-column>
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="角色编号" align="center" prop="guid" />
        <el-table-column label="角色名称" align="center" prop="name" />
        <el-table-column
          label="所属企业"
          align="center"
          prop="enterpriseSecret"
        />
        <el-table-column label="所属系统AppId" align="center" prop="appId" />
        <el-table-column
          label="创建时间"
          align="center"
          prop="createTime"
          width="180"
        >
          <template slot-scope="scope">
            <span>{{ $parseTime(scope.row.created) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="roleTotal"
        :page.sync="queryRoleParams.currentPage"
        :limit.sync="queryRoleParams.pageSize"
        @pagination="getRoleList"
      />
      <el-form label-width="100px">
        <el-form-item
          style="text-align: center; margin-left: -120px; margin-top: 30px"
        >
          <el-button
            type="primary"
            @click="submitUserRoleForm()"
            v-preventReClick
            >提交</el-button
          >
          <el-button @click="roleClose()">返回</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  listAllUser,
  getUser,
  delUser,
  addUser,
  updateUser,
  listRoleByUserGuid,
  addUserRole
} from '@/api/system/user'
import { listRole } from '@/api/system/role'
import { listOrgTree } from '@/api/system/org'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: 'User',
  components: { Treeselect },
  data() {
    return {
      countList: [], //统计数据
      allOrg: {},
      defaultExpandedOrgNode: [],
      userRoleList: [],
      chooseRoleList: [],
      siteId: null,
      queryRoleForm: {},
      roleList: [],
      roleOpen: false,
      curUserId: null,
      userEffectiveTime: '',
      userExpireTime: '',
      orgTreeLoading: false,
      //企业数据
      enterpriseOptions: [],
      curQuerySelectEnterpriseId: null,
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      //角色总数
      roleTotal: 0,
      // 用户表格数据
      userList: null,
      // 弹出层标题
      title: '',
      // 部门树选项
      deptOptions: null,
      //查询区域的部门
      queryDeptOptions: [],
      //系统站点id
      siteIdOptions: [],
      // 是否显示弹出层
      open: false,
      // 部门名称
      deptName: undefined,
      // 默认密码
      initPassword: undefined,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [
        { label: `未启用`, value: 0 },
        { label: `已禁用`, value: 1 },
        { label: `下次登录修改密码`, value: 2 },
        { label: `正常`, value: 4 }
      ],
      // 性别状态字典
      sexOptions: [
        { label: `男`, value: 0 },
        { label: `女`, value: 1 }
      ],
      // 岗位选项
      postOptions: [],
      // 角色选项
      roleOptions: [],
      // 表单参数
      form: {},
      defaultProps: {
        children: 'childrens',
        label: 'name'
      },

      // 查询参数
      queryRoleParams: {
        appId: undefined,
        enterpriseSecret: undefined,
        currentPage: 1,
        pageSize: 10,
        name: undefined
      },
      // 查询参数
      queryParams: {
        enterpriseSecret: undefined,
        currentPage: 1,
        pageSize: 10,
        loginName: undefined,
        phone: undefined,
        status: undefined
      },
      // 列信息
      columns: [
        { key: 0, label: `用户编号`, visible: true },
        { key: 1, label: `用户名称`, visible: true },
        { key: 2, label: `部门`, visible: true },
        { key: 3, label: `手机号码`, visible: true },
        { key: 4, label: `状态`, visible: true },
        { key: 5, label: `创建时间`, visible: true }
      ],
      // 表单校验
      rules: {
        name: [
          { required: true, message: '用户名称不能为空', trigger: 'blur' }
        ],
        orgGuid: [
          { required: true, message: '用户所在机构不能为空', trigger: 'blur' }
        ],
        sex: [{ required: true, message: '用户性别不能为空', trigger: 'blur' }],
        status: [
          { required: true, message: '用户状态不能为空', trigger: 'blur' }
        ],
        loginName: [
          { required: true, message: '登录名称不能为空', trigger: 'blur' }
        ],
        enterpriseSecret: [
          { required: true, message: '所属企业不能为空', trigger: 'blur' }
        ],
        email: [
          {
            type: 'email',
            message: "'请输入正确的邮箱地址",
            trigger: ['blur', 'change']
          }
        ],
        phone: [
          {
            required: true,
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }
        ],
        userEffectiveTime: [
          { required: true, message: '账号生效日期不能为空', trigger: 'blur' }
        ],
        userExpireTime: [
          { required: true, message: '账号失效日期不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 统计接口
    getCountInfo() {
      // 后端薛涛说加个orgGuid变更为orgGuids
      let data = {
        ...this.queryParams,
        orgGuids: this.queryParams.orgGuid
      }
      delete data.orgGuid
      // 如果清空了，则不传此参数
      if (this.queryParams.orgGuid.length === 0) {
        delete data.orgGuids
      }
      this.$request({
        url: '/orgPerson/v0.1/system/user/countUserBySite',
        method: 'post',
        data
      }).then((res) => {
        console.log('rrreess==>', res)
        this.countList = res?.data?.result || []
      })
    },
    //搜索区域
    querySelectChanged() {
      if (this.allOrg[this.queryParams.enterpriseid] != undefined) {
        this.queryDeptOptions = this.allOrg[this.queryParams.enterpriseid]
      } else {
        this.queryDeptOptions = null
      }
    },
    roleClose() {
      this.roleOpen = false
    },
    getRowKey(row) {
      return row.id
    },
    //人员角色保存
    submitUserRoleForm() {
      var userRoleList = []
      var param = {
        list: this.chooseRoleList,
        userGuid: this.curUserId
      }
      addUserRole(param).then((res) => {
        if (res.data.success) {
          this.$msgSuccess('新增成功')
          this.roleOpen = false
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    //角色得复选
    selectRoleRowChange(selection, row) {
      if (selection.length && selection.indexOf(row) !== -1) {
        //说明是选中
        var userRole = {
          guid: '',
          roleGuid: row.guid,
          userGuid: this.curUserId,
          siteId: row.siteId,
          enterpriseid: row.enterpriseid
        }
        this.chooseRoleList.push(userRole)
      } else {
        //取消
        for (var i = 0; i < this.chooseRoleList.length; i++) {
          if (this.chooseRoleList[i].roleGuid == row.guid) {
            this.chooseRoleList.splice(i, 1)
          }
        }
      }
    },
    handleRoleSelectionAll(selection) {
      if (selection.length > 0) {
        //说明是全选
        for (var i = 0; i < selection.length; i++) {
          var flag = false
          for (var j = 0; j < this.chooseRoleList.length; j++) {
            if (selection[i].guid == this.chooseRoleList[j].roleGuid) {
              flag = true
            }
          }
          if (!flag) {
            var userRole = {
              guid: '',
              roleGuid: selection[i].guid,
              userGuid: this.curUserId,
              siteId: selection[i].siteId,
              enterpriseid: selection[i].enterpriseid
            }
            this.chooseRoleList.push(userRole)
          }
        }
      } else {
        //取消全选
        for (var i = 0; i < this.roleList.length; i++) {
          var flag = false
          for (var j = 0; j < this.chooseRoleList.length; j++) {
            if (this.roleList[i].guid == this.chooseRoleList[j].roleGuid) {
              this.chooseRoleList.splice(j, 1)
              break
            }
          }
        }
      }
    },
    /** 查询角色列表 */
    getRoleList() {
      this.loading = true
      listRole(JSON.stringify(this.queryRoleParams)).then((res) => {
        if (res.data.success) {
          this.roleList = res.data.result.records
          this.roleTotal = res.data.result.total
          this.loading = false
          //回显
          this.echo(this.chooseRoleList)
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    getUserRole() {
      //根据用户查询用户角色表
      var params = {
        userGuid: this.curUserId
      }
      this.loading = true
      listRoleByUserGuid(params).then((res) => {
        if (res.data.success) {
          this.userRoleList = res.data.result
          for (var i = 0; i < this.userRoleList.length; i++) {
            var userRole = {
              roleGuid: this.userRoleList[i].roleGuid,
              userGuid: this.userRoleList[i].userGuid
            }
            this.chooseRoleList.push(userRole)
          }
          this.echo(this.userRoleList)
          this.loading = false
        } else {
          this.$msgError(res.data.message)
          this.loading = false
        }
      })
    },
    //回显
    echo(selectData) {
      selectData.forEach((item) => {
        this.roleList.forEach((row) => {
          if (row.guid == item.roleGuid) {
            this.$nextTick(() => {
              this.$refs.roleTable.toggleRowSelection(row, true)
            })
          }
        })
      })
    },
    //用户分配角色
    handleAuthRole(row) {
      this.curUserId = row.guid
      this.roleOpen = true
      //清空
      this.roleList = []
      this.queryRoleParams.appId = null
      //this.siteIdOptions=[];
      this.roleTotal = 0
      //查询角色列表
      this.queryRoleParams.enterpriseSecret = row.enterpriseSecret
      this.handleRoleQuery()
    },
    /** 查询站点列表 */
    getSiteList() {
      var params = {
        //enterpriseId:this.curQuerySelectEnterpriseId
      }
      getAllListByEnterpriseId(params).then((res) => {
        if (res.data.success) {
          this.siteIdOptions = res.data.result
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    selectChanged(value) {
      this.queryParams.enterpriseid = value
      this.form.enterpriseid = value
      this.curQuerySelectEnterpriseId = value
      this.getTreeselect()
    },
    //人员新增修改页面，修改企业
    updateSelectChanged() {
      this.deptOptions = null
      this.form.orgGuid = null
      this.getTreeselect()
    },
    selectSiteIdChanged(value) {
      this.queryRoleForm.siteId = value
      this.$forceUpdate()
    },

    /** 查询用户列表 */
    getList() {
      this.loading = true
      listAllUser(this.queryParams).then((res) => {
        if (res.data.success) {
          this.userList = res.data.result.records
          this.total = res.data.result.total
          this.loading = false
        } else {
          this.$msgError(res.data.message)
          this.loading = false
        }
      })
    },
    /** 查询部门下拉树结构 */
    getTreeselect() {
      if (this.allOrg[this.form.enterpriseSecret] != undefined) {
        this.deptOptions = this.allOrg[this.form.enterpriseSecret]
      } else {
        let params = {
          enterpriseSecret: this.form.enterpriseSecret
        }
        this.orgTreeLoading = true
        listOrgTree(params).then((res) => {
          if (res.data.success) {
            this.allOrg[this.form.enterpriseSecret] = res.data.result
            this.deptOptions = res.data.result
          } else {
            this.$msgError(res.data.message)
          }
          this.orgTreeLoading = false
        })
      }
    },
    openOrgTree() {
      debugger
      if (!this.form.enterpriseSecret) {
        this.$msgError('请先输入企业标识!')
      } else {
        this.getTreeselect()
      }
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 节点单击事件
    handleNodeClick(data) {
      this.queryParams.orgGuid = data.guid
      this.form.orgGuid = data.guid
      this.getList()
    },
    // 用户状态修改
    handleStatusChange(row) {
      let text = row.status === '0' ? '启用' : '停用'
      this.$confirm(
        '确认要"' + text + '""' + row.userName + '"用户吗?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(function () {
          return changeUserStatus(row.userId, row.status)
        })
        .then((res) => {
          if (res.data.success) {
            this.$msgSuccess(text + '成功')
          } else {
            this.$msgError(res.data.message)
            row.status = row.status === '0' ? '1' : '0'
          }
        })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        uGuid: undefined,
        orgGuid: null,
        orgList: [],
        uName: undefined,
        shortName: undefined,
        password: undefined,
        uLoginName: undefined,
        uPhone: undefined,
        uEmail: undefined,
        info: undefined,
        status: '0'
      }
      this.$resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.currentPage = 1
      this.getList()
    },
    /** 搜索按钮操作 */
    handleRoleQuery() {
      this.chooseRoleList = []
      this.queryRoleParams.currentPage = 1
      this.loading = true
      listRole(JSON.stringify(this.queryRoleParams)).then((res) => {
        if (res.data.success) {
          this.roleList = res.data.result.records
          this.roleTotal = res.data.result.total
          this.loading = false
          this.getUserRole()
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.resetQuery.orgGuid = []
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.userId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      //this.getTreeselect();
      this.open = true
      this.title = '添加用户'
      this.form.password = this.initPassword
      // this.getTreeselect();
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset()
      var params = {
        guid: row.guid
      }
      getUser(params).then((res) => {
        if (res.data.success) {
          this.form = res.data.result
          this.form.loginPwd = ''
          // this.userEffectiveTime=this.$parseTime(this.form.userEffectiveTime);
          // this.userExpireTime=this.$parseTime(this.form.userExpireTime);
          this.open = true
          this.title = '修改用户'
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    /** 重置密码按钮操作 */
    handleResetPwd(row) {
      this.$prompt('请输入"' + row.userName + '"的新密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          resetUserPwd(row.userId, value).then((res) => {
            if (res.data.success) {
              this.$msgSuccess('修改成功，新密码是：' + value)
            } else {
              this.$msgError(res.data.message)
            }
          })
        })
        .catch(() => {})
    },
    /** 提交按钮 */
    submitForm: function () {
      debugger
      console.log(this.form)
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (!this.form.loginPwd) {
            delete this.form.loginPwd
          }
          const date = new Date()
          date.setTime(this.form.userEffectiveTime)
          this.form.userEffectiveTime = date.getTime()
          date.setTime(this.form.userExpireTime)
          this.form.userExpireTime = date.getTime()
          if (this.form.guid != undefined) {
            updateUser(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess(res.data.message)
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          } else {
            addUser(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess(res.data.message)
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          }
        }
      })
    },
    /** 转换部门数据结构 */
    normalizer(node) {
      if (node.childrens && !node.childrens.length) {
        delete node.childrens
      }
      return {
        id: node.guid,
        label: node.name,
        children: node.childrens
      }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const userGuid = row.guid

      this.$confirm(
        '是否确认删除用户编号为"' + row.name + '"的数据项?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(function () {
          let data = {
            guid: userGuid
          }
          return delUser(data)
        })
        .then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
          }
        })
    }
  }
}
</script>
