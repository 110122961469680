<template>
  <div>
    <el-form
      style="background: #fff"
      ref="form"
      size="small"
      label-position="top"
      :model="formModel"
      class="demo-ruleForm"
      v-loading="loading"
    >
      <div
        style="
          line-height: 40px;
          text-indent: 20px;
          border-bottom: 1px solid #eee;
          font-weight: 700;
        "
      >
        发票抬头信息
      </div>
      <el-row :gutter="24" style="padding: 20px">
        
        <el-col :span="12">
          <el-form-item
            label="企业名称"
            prop="enterpriseName"
            :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
          >
            <el-input style="width:300px" v-model="formModel.enterpriseName" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="纳税人识别码"
            prop="taxPayerCode"
            :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
          >
            <el-input style="width:300px"  v-model="formModel.taxPayerCode" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="开户行"
            prop="bankName"
            :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
          >
            <el-input  style="width:300px"  v-model="formModel.bankName" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="开户行账号"
            prop="bankCode"
            :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
          >
            <el-input style="width:300px"  v-model="formModel.bankCode" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="电话"
            prop="enterpriseTelephone"
            :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
          >
            <el-input
            style="width:300px" 
              v-model="formModel.enterpriseTelephone"
              placeholder="请输入"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="发票类型"
            prop="invoiceCategory"
            :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
          >
            <el-radio-group v-model="formModel.invoiceCategory">
                <el-radio label="01">普票</el-radio>
                <el-radio label="02" style="margin-left: 100px">专票</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            label="地址"
            prop="enterpriseRegAddr"
            :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
          >
            <el-input
            style="width:670px" 
              v-model="formModel.enterpriseRegAddr"
              placeholder="请输入"
            />
            <!-- <addressPage
              :etpRegAddress="etpRA"
              @addr-sent="initAddr"
            ></addressPage> -->
          </el-form-item>
        </el-col>
      </el-row>
      <div
        style="
          line-height: 40px;
          text-indent: 20px;
          border-bottom: 1px solid #eee;
          font-weight: 700;
        "
      >
        其他信息
      </div>
      <el-row :gutter="24" style="padding: 20px">
        <el-col :span="12">
          <el-form-item
            label="联系人姓名"
            prop="applicant"
            :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
          >
            <el-input style="width:300px"  v-model="formModel.applicant" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="联系人手机号"
            prop="telephone"
            :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
          >
            <el-input
            style="width:300px" 
              maxlength="11"
              v-model="formModel.telephone"
              placeholder="请输入"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="电子邮箱"
            prop="mailbox"
            :rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
          >
            <el-input style="width:300px"  v-model="formModel.mailbox" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <!-- <el-col :span="24">
          <el-form-item
            label="联系地址"
            prop="applicantArea"
            :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
          >
            <addressPage
              :etpRegAddress="etpRA2"
              @addr-sent="initAddr2"
            ></addressPage>
          </el-form-item>
        </el-col> -->
      </el-row>
    </el-form>
      <el-divider></el-divider>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitAddInvoice">确 定</el-button>
      </div>
   
  </div>
</template>

<script>
import {addInvoice} from '@/api/guarantee/finance'
export default {
data() {
        return {
            formModel:{},
            loading:false,
            myValue: undefined
        }
    },
    name: 'addInvoice',
    props: {
        value: {
            type: String,
            default: ''
        },
    },
    mounted() {

    },
    created() {

    },
    methods: {
        submitAddInvoice() {
           this.loading=true
           this.formModel.guaranteeIds=this.value
           console.log(this.formModel)
           addInvoice(this.formModel).then((res) => {
                if (res.data.success) {
                    this.loading=false
                      this.$message.success('申请信息保存成功')
                    this.$emit('syncInvoiceVisible')
                }else{
                    this.$message.error(res.data.message)
                    this.loading=false
                }
           })
        },

    },
    watch: {
        value(newValue) {
             this.myValue = newValue;
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0 0;
}
</style>