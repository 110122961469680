<template>
  <div v-if="bankPayHtml" v-html="bankPayHtml"></div>
  <div v-else class="app-container">
    <div style="width: 70%; margin: 20px auto">
      <el-steps :active="isPage">
        <el-step title="填写企业信息" description=""></el-step>
        <el-step title="填写申请信息" description=""></el-step>
        <el-step title="付款" description=""></el-step>
        <el-step title="开立保函" description=""></el-step>
      </el-steps>
    </div>
    <div class="applyFor" v-loading="dataLoading">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          padding: 10px 0;
        "
      >
        <i
          style="font-size: 26px; margin-right: 10px"
          class="el-icon-document"
        ></i>
        <template v-if="isPage == 2">
          <span v-if="formModel.status == '05'"
            >您的保函申请已被拒保，请到保函管理里查看拒保原因及钱款去向</span
          >
          <span v-else>保函待支付，请尽快支付</span>
        </template>
        <template v-else>
          <div v-if="formModel.status == '03' || formModel.status == '06'">
            保函已开立，请下载
          </div>
          <div v-else>保函开立中，请耐心等待</div>
        </template>
      </div>
      <el-form
        style="background: #fff"
        ref="form"
        size="small"
        :model="formModel"
        class="demo-ruleForm"
      >
        <div
          style="
            line-height: 40px;
            margin-bottom: 20px;
            border-bottom: 1px solid #eee;
            font-weight: 700;
          "
        >
          支付信息
        </div>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="订单状态：">
              <span class="el_item_text">{{ formModel.statusName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="付款金额（人民币）：">
              <span class="el_item_text"
                >{{ formModel.guaranteePayMoney }} 元</span
              >
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请时间：">
              <span class="el_item_text">{{ formModel.created }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-if="isPage == 2 && isPaybtn == 1">
            <el-form-item label="请选择支付方式：">
              <el-radio-group v-model="radio">
                <el-radio :label="0">
                  <span class="el_item_text">个人扫码支付</span
                  ><span class="el_item_text red"
                    >（截图或转发二维码支付无效）</span
                  ></el-radio
                >
                <el-radio :label="1">
                  <span class="el_item_text">企业网银支付</span
                  ><span class="el_item_text red"
                    >（需要对公网银）</span
                  ></el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-else>
            <el-form-item label="支付方式：">
              <span class="el_item_text">扫码支付</span>
            </el-form-item>
          </el-col>
          <el-col :span="20" v-if="isPage == 2 && isPaybtn == 1">
            <el-form-item style="text-align: center">
              <el-button
                size="medium"
                style="width: 300px"
                type="primary"
                @click="goPay"
                >去支付</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <div
          style="
            border-bottom: 1px solid #eee;
            font-weight: 700;
            margin-bottom: 10px;
            padding: 10px 0;
          "
        >
          投标企业信息
        </div>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="企业名称：">
              {{ companyInfo.etpName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="社会信用代码：">
              {{ companyInfo.etpUscc }}
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="注册资本：">
              {{ companyInfo.etpRegCapital }} 万元
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属行业：">
              {{ companyInfo.etpIndustryName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业性质：">
              {{ companyInfo.etpPropertyName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="注册地址：">
              {{ companyInfo.etpAddrFullName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件签发日期：">
              {{
                companyInfo.etpCertBeginDate +
                ' 至 ' +
                companyInfo.etpCertEndDate
              }}
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="营业执照：">
              <uploadBtn v-model="companyInfo.etpIdcard" :limit="upload.limit" :viewImage="upload.viewImage">
              </uploadBtn>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="上年度资产规模：">
              {{ formModel.etpLastAssets }} 万元
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上年度营业收入：">
              {{ formModel.etpLastIncome }} 万元
            </el-form-item>
          </el-col>
          <el-col :span="24" style="margin-top: -20px">
            <div style="display: flex; align-items: center">
              <span style="padding-top: 8px">营业执照：</span>
              <uploadBtn
                v-model="companyInfo.etpIdcard"
                :limit="upload.limit"
                :viewImage="upload.viewImage"
              >
              </uploadBtn>
            </div>
          </el-col>
        </el-row>
        <div
          style="
            border-bottom: 1px solid #eee;
            font-weight: 700;
            margin-bottom: 10px;
            padding: 10px 0;
          "
        >
          项目信息
        </div>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="项目名称：">
              <span class="el_item_text">{{ formModel.projectName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目编号：">
              <span class="el_item_text">{{ formModel.projectCode }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="招标单位：">
              <span class="el_item_text">{{ formModel.bidCompanyName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="招标截止日期：">
              <span class="el_item_text">{{ formModel.bidEndDate }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="担保金额（人民币）：">
              <span class="el_item_text">{{ formModel.guaranteeMoney }}元</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="保函有效期：">
              <span class="el_item_text">{{ formModel.guaranteeDays }}天</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收件人：">
              {{ formModel.guaranteePostLxr }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收件人电话：">
              {{ formModel.guaranteePostLxrTelephone }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="保函邮寄地址：">
              {{ formModel.guaranteePostAddrFullName }}
            </el-form-item>
          </el-col>
          <el-col v-if="companyInfo.guaranteeDocument == '03'" :span="12">
            <el-form-item style="margin-top: -15px">
              <div
                style="display: flex; align-items: center; margin-top: -25px"
              >
                <span style="padding-top: 40px">选择的保函文本：</span>
                <uploadBtn
                  v-model="companyInfo.guaranteeDocumentExt"
                  :limit="upload.limit"
                  :viewImage="upload.viewImage"
                >
                </uploadBtn>
              </div>
            </el-form-item>
          </el-col>
          <el-col v-else :span="12">
            <el-form-item label="选择的保函文本">
              <div
                v-if="companyInfo.guaranteeDocument == '01'"
                style="
                  text-align: left;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                "
                @click="pdfBtn"
              >
                <img
                  style="width: 30px; height: 30px"
                  class="yulan_img"
                  src="../../../assets/img/Pdf.png"
                  alt=""
                />
                <span
                  style="font-size: 14px; padding-left: 15px; color: #3d8bfc"
                  >保函文本（通用）点击预览</span
                >
              </div>
              <div
                v-if="companyInfo.guaranteeDocument == '02'"
                style="
                  text-align: left;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                "
                @click="pdfBtn"
              >
                <img
                  style="width: 30px; height: 30px"
                  class="yulan_img"
                  src="../../../assets/img/Pdf.png"
                  alt=""
                />
                <span
                  style="font-size: 14px; padding-left: 15px; color: #3d8bfc"
                  >保函文本（独立）点击预览</span
                >
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item style="margin-top: -15px">
              <div style="display: flex; align-items: center">
                <span style="padding-top: 10px"
                  >招标方发布的招标（采购）文件：</span
                >
                <uploadBtn
                  v-model="companyInfo.bidDocument"
                  :limit="upload.limit"
                  :viewImage="upload.viewImage"
                >
                </uploadBtn>
              </div>
            </el-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="formModel.status == '03' || formModel.status == '06'"
          >
            <el-form-item label="已开立保函：">
              <div
                style="
                  text-align: left;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                "
                @click="downClick"
              >
                <img
                  style="width: 30px; height: 30px"
                  src="../../../assets/img/Pdf.png"
                  alt=""
                />
                <span
                  style="font-size: 14px; padding-left: 15px; color: #3d8bfc"
                  >点击下载</span
                >
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="我要给客服留言：">
              <span class="el_item_text">{{ formModel.remark }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-dialog :visible.sync="dialogVisible" width="40%">
        <div v-html="payData"></div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import { getDetailZtb, getZtbPay } from '@/api/adminData/index'
import { getDetailZtb, getZtbPay } from '@/api/system/applyFor'
import { parseTime } from '@/utils/DateUtils'
import uploadBtn from '@/components/uploadBtn/index.vue'
import { detailUpload, downloadFile } from '@/api/store/file'
export default {
  components: { uploadBtn },
  data() {
    return {
      dataLoading: false,
      bankPayHtml: '',
      companyInfo: {},
      upload: {
        limit: 1,
        viewImage: true
      },
      isPage: 2, // 0填写申请信息 1付款信息  2开立保函
      timer: null, // 定时器
      dialogVisible: false,
      radio: 0,
      formModel: {}, // 项目信息
      guaranteeId: '',
      etpRA: {},
      payData: null,
      payType: '00', // 支付方式 00:二维码支付;01:企业网银在线支付
      isPaybtn: 1
    }
  },
  destroyed() {
    // 离开页面是销毁
    clearInterval(this.timer)
    this.timer = null
  },
  mounted() {
    this.isPage = this.$route.query.type ? Number(this.$route.query.type) : 2
    this.isPaybtn = this.$route.query.isPaybtn || 1
    this.guaranteeId = this.$route.query.guaranteeId
    this.getDetailData()
    if (this.isPage == 2) {
      this.timer = window.setInterval(() => {
        setTimeout(this.getDetailDataStatue(), 0)
      }, 3000)
    }
  },
  methods: {
    pdfBtn() {
      if (this.formModel.guaranteeDocument === '01') {
        window.open(
          'https://api.yinbaobiao.com/web/ybb/upload/document-doc/tb-common.pdf',
          '_blank'
        )
      } else if (this.formModel.guaranteeDocument === '02') {
        window.open(
          'https://api.yinbaobiao.com/web/ybb/upload/document-doc/tb-alone-guarantee.pdf',
          '_blank'
        )
      }
    },
    downClick() {
      const data = {
        guid: this.formModel.returnGuarantee
      }
      this.dataLoading = true
      detailUpload(data).then((ress) => {
        this.dataLoading = false
        if (ress.data.success && ress.data.result) {
          console.log(ress.data.result)
          this.dataLoading = true
          downloadFile(data).then((res) => {
            this.dataLoading = false
            const content = res.data
            const blob = new Blob([content], { type: 'application/pdf' })
            const fileName = ress.data.result.fileName
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          })
        } else {
          this.$msgError('文件无效')
        }
      })
    },

    goPay() {
      // 支付
      if (this.radio == 0) {
        // 二维码
        getZtbPay({
          guaranteeId: this.guaranteeId,
          payType: '00'
        }).then((res) => {
          if (res.data.success) {
            this.payData = res.data.result
            this.dialogVisible = true
          } else {
            this.$message.error(res.data.message)
          }
          this.contentLoading = false
        })
      } else {
        // 银行卡
        debugger
        getZtbPay({
          guaranteeId: this.guaranteeId,
          payType: '01'
        }).then((res) => {
          if (res.data.success) {
            const newWindow = window.open('', '_blank')
            if (newWindow) {
              // 获取HTML内容

              // 写入HTML内容到新窗口
              newWindow.document.write(res.data.result)
              // 或者
              // newWindow.document.body.innerHTML = htmlContent;
            }
            //this.bankPayHtml = res.result
          } else {
            this.$message.error(res.data.message)
          }
          this.contentLoading = false
        })
      }
    },
    getDetailData() {
      // 获取详情
      this.dataLoading = true
      getDetailZtb({ guaranteeId: this.guaranteeId }).then((res) => {
        this.dataLoading = false
        if (res.data.success) {
          // 企业信息
          this.companyInfo = res.data.result
          this.companyInfo.etpCertBeginDate = parseTime(
            this.companyInfo.etpCertBeginDate,
            '{y}-{m}-{d}'
          )
          this.companyInfo.etpCertEndDate = parseTime(
            this.companyInfo.etpCertEndDate,
            '{y}-{m}-{d}'
          )
          this.formModel = res.data.result
          this.formModel.bidEndDate = parseTime(
            this.formModel.bidEndDate,
            '{y}-{m}-{d}'
          )
          this.formModel.guaranteeBeginDate = parseTime(
            this.formModel.guaranteeBeginDate,
            '{y}-{m}-{d}'
          )
          this.formModel.guaranteeEndDate = parseTime(
            this.formModel.guaranteeEndDate,
            '{y}-{m}-{d}'
          )
          this.formModel.created = parseTime(
            this.formModel.created,
            '{y}-{m}-{d} {h}:{i}:{s}'
          )
        } else {
          this.$message.error(res.data.message)
        }
        this.contentLoading = false
      })
    },
    getDetailDataStatue() {
      // 查询支付状态
      getDetailZtb({ guaranteeId: this.guaranteeId }).then((res) => {
        if (res.data.success) {
          if (res.data.result.status != '00') {
            // 支付完成跳转开立保函
            if (this.isPaybtn == 1) {
              this.$router.push({
                path: '/applyForPay',
                query: {
                  type: 3,
                  guaranteeId: this.guaranteeId
                }
              })
              location.reload()
            }
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.applyFor {
  width: 1200px;
  margin: 0 auto;
}

::v-deep .el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0 0;
}

.el_item_text {
  color: #000;
}

.red {
  color: #f00 !important;
}

::v-deep .el-dialog__body {
  padding: 0 0;
}

::v-deep .el-dialog__header {
  padding: 0;
  padding-bottom: 0;
}

::v-deep .djsc_btn {
  display: none;
}
::v-deep .el-upload {
  display: none;
}
</style>
