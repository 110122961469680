<template>
  <div>
    <el-form
          :model="form"
          ref="form"
          :inline="true"
          size="mini"
        >
                <el-form-item label="所属企业" prop="enterpriseid">
                        <el-select
                            v-loading="etploading"
                            v-model="form.enterpriseSecret"
                            @change="changeEtp"
                        >
                            <el-option
                            v-for="item in enterpriseOptions"
                            :key="item.code"
                            :value="item.code"
                            :label="item.name"
                            ></el-option>
                        </el-select>
                    </el-form-item>
            <el-form-item label="所属系统" prop="appId">
                <el-select v-loading="etpSystemloading"
                v-model="form.appId"
                @change="changeSystem"
                >
                <el-option  v-for="item1 in enterpriseSystemOptions"
                :key="item1.code" 
                :value="item1.code" 
                :label="item1.name">
                </el-option>
                </el-select>
                <!-- <el-input v-model="form.appId" placeholder="请输入系统appId">
                </el-input> -->
            </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  listEnterprise
} from '@/api/system/enterprise'
import {
  listApplication
} from '@/api/system/application'
export default {
    name:'etpSystem',
    data(){
        return {
            etploading:false,
            etpSystemloading:false,
            enterpriseOptions:[],
            enterpriseSystemOptions:[],
            form:{
                appId:this.appId,
                enterpriseSecret:this.enterpriseSecret

            }
        }
    },
    props:{
       appId:{
         type: String,
         default: ''
       },
       enterpriseSecret:{
        type: String,
        default:'',
       }
    },
    created(){

    },
    mounted(){
        this.getEnterpriseList()
    },
    methods:{
        /**获取企业列表 */
        getEnterpriseList() {
            this.etploading = true
            this.enterpriseOptions=[]
            const data={
                        currentPage: 1,
                pageSize: 100
            }
            listEnterprise(data).then((res) => {
                this.etploading=false
                if (res.data.success) {
                let enterpriseList= res.data.result.records
                enterpriseList.forEach(element => {
                    let etp={}
                    etp.code=element.enterpriseSecret
                    etp.name=element.name
                    this.enterpriseOptions.push(etp)
                })
                console.log(this.enterpriseOptions)
                } else {
                this.$msgError(res.data.message)
                }
            })
        },
        //获取企业系统
        getEtpSystem(){
            this.etpSystemloading = true
            this.enterpriseSystemOptions=[]
            const data={
                        currentPage: 1,
                pageSize: 100,
                enterpriseSecret:this.form.enterpriseSecret
            }
            listApplication(data).then((res) => {
                this.etpSystemloading=false
                if (res.data.success) {
                let appList= res.data.result.records
                appList.forEach(element => {
                    let app={}
                    app.code=element.appid
                    app.name=element.name
                    this.enterpriseSystemOptions.push(app)
                })
                console.log(this.enterpriseSystemOptions)
                } else {
                this.$msgError(res.data.message)
                }
            })
        },
        changeEtp(){
             this.$emit('getEtpSecret', this.form.enterpriseSecret);
            this.getEtpSystem();
        },
        changeSystem(){
            this.$emit('getAppId', this.form.appId);
        }

    },
    watch:{
        enterpriseSecret(newVal){
            this.form.enterpriseSecret=newVal
            this.enterpriseSystemOptions=[]
        },
        appId(newVal){
            this.form.appId=newVal
        }
    }
}
</script>

<style>

</style>