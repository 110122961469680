<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      v-show="showSearch"
      :inline="true"
    >
      <el-form-item label="企业全称/企业简称" prop="name">
        <el-input
          v-model.trim="queryParams.name"
          placeholder="请输入企业全称/企业简称"
          clearable
          size="small"
          style="width: 240px"
        />
      </el-form-item>
      <el-form-item label="企业标识" prop="enterpriseSecret">
        <el-input
          v-model.trim="queryParams.enterpriseSecret"
          placeholder="请输入企业企业标识"
          clearable
          size="small"
          style="width: 240px"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          v-preventReClick
          >搜索
        </el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置
        </el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-preventReClick
          v-if="menuPriPath.indexOf('system/enterprise/create') >= 0"
          >新增
        </el-button>
      </el-col>

      <right-toolbar
        :showSearch.sync="showSearch"
        @queryTable="getList"
      ></right-toolbar>
    </el-row>

    <el-table
      v-loading="loading"
      :data="enterpriseList"
      size="mini"
      fit
      border
      @selection-change="handleSelectionChange"
    >
      <!--      <el-table-column type="selection" width="55" align="center" />-->
      <!--      <el-table-column label="guid" prop="guid" width="240" />-->
      <el-table-column
        label="操作"
        header-align="center"
        align="center"
        width="150"
        class-name="small-padding fixed-width"
        fixed
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            autofocus
            v-if="menuPriPath.indexOf('system/enterprise/update') >= 0"
            v-preventReClick
            >修改
          </el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-if="menuPriPath.indexOf('system/enterprise/delete') >= 0"
            v-preventReClick
            >删除
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="企业全称"
        prop="name"
        header-align="left"
        align="left"
        width="250"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="openDetail(scope.row)">{{
            scope.row.name
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="企业简称"
        prop="shortName"
        header-align="left"
        align="left"
        width="150"
      />
      <el-table-column
        label="企业标识"
        prop="enterpriseSecret"
        header-align="left"
        align="left"
        width="150"
      />
      <el-table-column
        label="统一社会信用代码"
        prop="creditCode"
        header-align="left"
        align="left"
        width="200"
      />
      <el-table-column
        label="联系人"
        prop="contactPersonName"
        header-align="left"
        align="left"
        width="150"
      />
      <el-table-column
        label="联系人手机号"
        prop="contactPersonPhone"
        header-align="left"
        align="left"
        width="150"
      />
      <el-table-column
        label="运营负责人"
        prop="principalGuid"
        header-align="left"
        align="left"
        width="150"
      />
      <el-table-column
        label="状态"
        prop="status"
        header-align="center"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.status == 1">启用</div>
          <div v-else>停用</div>
        </template>
      </el-table-column>
      
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.currentPage"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改企业配置对话框 -->
    <el-dialog
      :title="dialog.title"
      v-if="dialog.open"
      :visible.sync="dialog.open"
      width="40%"
      append-to-body
    >
      <div>
        <el-steps
          :active="dialog.step"
          align-center
          process-status="process"
          finish-status="success"
        >
          <el-step title="基本信息"></el-step>
          <el-step title="法人信息"></el-step>
          <el-step title="运营信息"></el-step>
        </el-steps>
        <div class="dialog-form">
          <el-form
            ref="formStep1"
            :model="dialog.form"
            :rules="dialog.rules"
            label-width="160px"
            v-show="dialog.step === 0"
          >
            <el-form-item label="企业全称" prop="name">
              <el-input
                v-model.trim="dialog.form.name"
                placeholder="请输入企业名称"
              />
            </el-form-item>
            <el-form-item label="企业简称" prop="shortName">
              <el-input
                v-model.trim="dialog.form.shortName"
                placeholder="请输入企业简称"
              />
            </el-form-item>
            <el-form-item label="企业标识" prop="enterpriseSecret">
              <el-input
                v-model.trim="dialog.form.enterpriseSecret"
                placeholder="请输入企业标识"
              />
            </el-form-item>
            <el-form-item label="统一社会信用代码" prop="creditCode">
              <el-input
                v-model.trim="dialog.form.creditCode"
                placeholder="请输入统一社会信用代码"
              />
            </el-form-item>
            <el-form-item label="详细地址" prop="address">
              <el-input
                v-model.trim="dialog.form.address"
                placeholder="请输入详细地址"
              />
            </el-form-item>
            <el-form-item label="营业执照扫描件" prop="businessLicenseId">
              <image-upload
                :fileIds="dialog.form.businessLicenseIds"
                :limit="1"
                @syncFileIds="getUploadFileIds($event, 'businessLicenseId')"
              />
            </el-form-item>
            <el-form-item label="联系人" prop="contactPersonName">
              <el-input
                v-model.trim="dialog.form.contactPersonName"
                placeholder="请输入联系人姓名"
              />
            </el-form-item>
            <el-form-item label="联系人手机号" prop="contactPersonPhone">
              <el-input
                v-model.trim="dialog.form.contactPersonPhone"
                placeholder="请输入联系人手机号"
              />
              <div class="tip">
                首次提交的联系人手机号，用于开通企业的登录账户
              </div>
            </el-form-item>
            <el-form-item label="联系人邮箱" prop="contactPersonEmail">
              <el-input
                v-model.trim="dialog.form.contactPersonEmail"
                placeholder="请输入联系人邮箱"
              />
              <div class="tip">用于接收企业邮件</div>
            </el-form-item>
          </el-form>
          <el-form
            ref="formStep2"
            :model="dialog.form"
            :rules="dialog.rules"
            label-width="160px"
            v-show="dialog.step === 1"
          >
            <el-form-item label="法人姓名" prop="legalPersonName">
              <el-input
                v-model.trim="dialog.form.legalPersonName"
                placeholder="请输入法人姓名"
              />
            </el-form-item>
            <el-form-item label="法人身份证号" prop="legalPersonCard">
              <el-input
                v-model.trim="dialog.form.legalPersonCard"
                placeholder="请输入法人身份证号"
              />
            </el-form-item>
            <el-form-item label="身份证有效期" prop="cardCreateTime">
              <el-date-picker
                v-model="dialog.form.cardCreateTime"
                type="date"
                placeholder="开始日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
              <span>至</span>
              <el-date-picker
                v-model="dialog.form.cardEndTime"
                type="date"
                placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :disabled="dialog.form.cardLongTerm"
              />
              <el-checkbox v-model="dialog.form.cardLongTerm"
                >长期有效</el-checkbox
              >
            </el-form-item>
            <el-form-item label="身份证正面" prop="cardFront">
              <image-upload
                :fileIds="dialog.form.cardFronts"
                :limit="1"
                @syncFileIds="getUploadFileIds($event, 'cardFront')"
              />
            </el-form-item>
            <el-form-item label="身份证背面" prop="cardBack">
              <image-upload
                :fileIds="dialog.form.cardBacks"
                :limit="1"
                @syncFileIds="getUploadFileIds($event, 'cardBack')"
              />
            </el-form-item>
          </el-form>
          <el-form
            ref="formStep3"
            :model="dialog.form"
            :rules="dialog.rules"
            label-width="160px"
            v-show="dialog.step === 2"
          >
            <el-form-item label="运营负责人" prop="principalGuid">
              <el-input
                v-model.trim="dialog.form.principalGuid"
                placeholder="请输入运营负责人姓名"
              />
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button v-preventReClick @click="previous" v-if="dialog.step > 0"
          >上一步</el-button
        >
        <el-button
          type="primary"
          v-preventReClick
          @click="next"
          v-if="dialog.step < 2"
          >下一步</el-button
        >
        <el-button
          type="primary"
          v-preventReClick
          @click="submitForm"
          v-if="dialog.step === 2"
          >提交</el-button
        >
        <!--        <el-button @click="cancel">取 消</el-button>-->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addEnterprise,
  delEnterprise,
  getEnterprise,
  listEnterprise,
  updateEnterprise
} from '@/api/system/enterprise'
import { getMenuPriByMenuId } from '@/api/menu/index'
import ImageUpload from '@/components/ImageUpload/index.vue'
import {
  valid2ndIdCardNo,
  validCreditCode,
  validEmail,
  validMobile
} from '@/utils/validate'
import moment from 'moment'

export default {
  name: 'enterprise',
  components: {
    ImageUpload
  },
  data() {
    const _validateCardValidity = (rule, value, callback) => {
      let { cardCreateTime, cardEndTime, cardLongTerm } = this.dialog.form
      if (!cardCreateTime && !cardEndTime && !cardLongTerm) {
        return callback(new Error('身份证有效期不能为空'))
      }
      if (!cardCreateTime) {
        return callback(new Error('身份证有效期开始日期不能为空'))
      }
      if (!cardEndTime && !cardLongTerm) {
        return callback(new Error('身份证有效期结束日期不能为空'))
      }
      if (
        !cardLongTerm &&
        Date.parse(cardCreateTime) >= Date.parse(cardEndTime)
      ) {
        return callback(new Error('身份证有效期输入有误，请检查'))
      }
      return callback()
    }
    const _validateCreditCode = (rule, value, callback) => {
      if (value && !validCreditCode(value)) {
        return callback(new Error('统一社会信用代码输入有误，请检查'))
      }
      return callback()
    }
    const _validateEmail = (rule, value, callback) => {
      if (value && !validEmail(value)) {
        return callback(new Error('邮箱地址输入有误，请检查'))
      }
      return callback()
    }
    const _validateMobile = (rule, value, callback) => {
      if (value && !validMobile(value)) {
        return callback(new Error('手机号输入有误，请检查'))
      }
      return callback()
    }
    const _validateIdCardNo = (rule, value, callback) => {
      if (value && !valid2ndIdCardNo(value)) {
        return callback(new Error('身份证号输入有误，请检查'))
      }
      return callback()
    }
    return {
      menuPriPath: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 角色表格数据
      enterpriseList: [],
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],

      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        roleName: undefined,
        roleKey: undefined,
        status: undefined,
        enterpriseSecret: undefined,
        name: undefined
      },
      // 表单参数
      form: {},
      defaultProps: {
        children: 'childrens',
        label: 'menuName'
      },
      orgDefaultProps: {
        children: 'childrens',
        label: 'name'
      },
      opts: {
        orgUserTree: [],
        orgUserTreeProps: {
          children: 'childrens',
          label: 'name',
          value: 'guid'
        }
      },
      // 新增表单
      dialog: {
        // 弹出层标题
        title: '',
        // 是否显示弹出层
        open: false,
        step: 0,
        form: {
          name: '',
          shortName: '',
          enterpriseSecret: '',
          creditCode: '',
          address: '',
          businessLicenseIds: [],
          businessLicenseId: '',
          contactPersonName: '',
          contactPersonPhone: '',
          contactPersonEmail: '',
          legalPersonName: '',
          legalPersonCard: '',
          cardCreateTime: '',
          cardEndTime: '',
          cardLongTerm: false,
          cardFronts: [],
          cardFront: '',
          cardBacks: [],
          cardBack: '',
          principalGuids: [],
          principalGuid: ''
        },
        // 表单校验
        rules: {
          name: [
            { required: true, message: '企业全称不能为空', trigger: 'blur' }
          ],
          shortName: [
            { required: true, message: '企业简称不能为空', trigger: 'blur' }
          ],
          enterpriseSecret: [
            { required: true, message: '企业标识不能为空', trigger: 'blur' }
          ],
          creditCode: [
            {
              required: true,
              message: '统一社会信用代码不能为空',
              trigger: 'blur'
            },
            { validator: _validateCreditCode, trigger: 'blur' }
          ],
          address: [
            { required: true, message: '详细地址不能为空', trigger: 'blur' }
          ],
          businessLicenseId: [
            {
              required: false,
              message: '营业执照扫描件不能为空',
              trigger: 'change'
            }
          ],
          contactPersonName: [
            { required: true, message: '联系人不能为空', trigger: 'blur' }
          ],
          contactPersonPhone: [
            {
              required: true,
              message: '联系人手机号不能为空',
              trigger: 'blur'
            },
            { validator: _validateMobile, trigger: 'blur' }
          ],
          contactPersonEmail: [
            { required: true, message: '联系人邮箱不能为空', trigger: 'blur' },
            { validator: _validateEmail, trigger: 'blur' }
          ],
          legalPersonName: [
            { required: true, message: '法人姓名不能为空', trigger: 'blur' }
          ],
          legalPersonCard: [
            {
              required: false,
              message: '法人身份证号不能为空',
              trigger: 'blur'
            },
            { validator: _validateIdCardNo, trigger: 'blur' }
          ],
          cardCreateTime: [
            {
              required: true,
              validator: _validateCardValidity,
              trigger: 'change'
            }
          ],
          cardFront: [
            {
              required: false,
              message: '身份证正面不能为空',
              trigger: 'change'
            }
          ],
          cardBack: [
            {
              required: false,
              message: '身份证背面不能为空',
              trigger: 'change'
            }
          ],
          principalGuids: [
            {
              required: false,
              message: '运营负责人不能为空',
              trigger: 'change'
            }
          ]
        }
      }
    }
  },
  created() {
    //debugger

    this.getList()
    this.getRolePriList()
  },
  methods: {
    /** 查询角色列表 */
    getRolePriList() {
      debugger
      var data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (var i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },

    /**获取企业列表 */
    getList() {
      //debugger
      this.loading = true
      listEnterprise(JSON.stringify(this.queryParams)).then((res) => {
        if (res.data.success) {
          this.enterpriseList = res.data.result.records
          this.total = res.data.result.total
          this.loading = false
        } else {
          this.$msgError(res.data.message)
        }
      })
    },

    // 取消按钮
    cancel() {
      this.dialog.open = false
      this.reset()
    },
    // 取消按钮（数据权限）
    cancelDataScope() {
      this.openDataScope = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.dialog.form = {
        name: '',
        shortName: '',
        enterpriseSecret: '',
        creditCode: '',
        address: '',
        businessLicenseIds: [],
        businessLicenseId: '',
        contactPersonName: '',
        contactPersonPhone: '',
        contactPersonEmail: '',
        legalPersonName: '',
        legalPersonCard: '',
        cardCreateTime: '',
        cardEndTime: '',
        cardLongTerm: false,
        cardFronts: [],
        cardFront: '',
        cardBacks: [],
        cardBack: '',
        principalGuids: [],
        principalGuid: ''
      }
      //this.$resetForm('formStep1');
      //this.$resetForm('formStep2');
      //this.$resetForm('formStep3');
      this.dialog.step = 0
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.currentPage = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.roleId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.dialog.open = true
      this.dialog.title = '添加企业'
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      debugger
      this.reset()
      var params = {
        guid: row.guid
      }
      getEnterprise(params).then((res) => {
        if (res.data.success) {
          Object.assign(this.dialog.form, res.data.result)
          if (this.dialog.form.businessLicenseId) {
            this.dialog.form.businessLicenseIds.push(
              this.dialog.form.businessLicenseId
            )
          }
          if (this.dialog.form.cardFront) {
            this.dialog.form.cardFronts.push(this.dialog.form.cardFront)
          }
          if (this.dialog.form.cardBack) {
            this.dialog.form.cardBacks.push(this.dialog.form.cardBack)
          }

          this.dialog.form.cardCreateTime = moment(
            this.dialog.form.cardCreateTime
          ).format('YYYY-MM-DD')
          this.dialog.form.cardEndTime = moment(
            this.dialog.form.cardEndTime
          ).format('YYYY-MM-DD')
          if (this.dialog.form.cardEndTime === '9999-12-31') {
            this.dialog.form.cardEndTime = ''
            this.dialog.form.cardLongTerm = true
          } else {
            this.dialog.form.cardLongTerm = false
          }

          // if (this.dialog.form.principalGuid) {
          //   let params = {
          //     "guid": this.dialog.form.principalGuid
          //   };
          //   getUser(params).then(response => {
          //     let params = {
          //       "guid": response.data.result.orgGuid
          //     };
          //     selectAllParentByGuid(params).then(response => {
          //       let deptGuids = []
          //       response.data.result.forEach(dept => deptGuids.push(dept.guid))
          //       deptGuids.push(this.dialog.form.principalGuid)
          //       this.dialog.form.principalGuids = deptGuids
          //     })
          //   })
          // }
          this.dialog.open = true
          this.dialog.title = '修改企业'
        } else {
          this.$msgError(res.data.message)
        }
      })
    },

    /** 附件ID回传 */
    getUploadFileIds(val, obj) {
      this.dialog.form[obj + 's'] = val
      this.dialog.form[obj] = val.join(',')
      const form = this.dialog.step === 0 ? 'formStep1' : 'formStep2'
      this.$refs[form].clearValidate(obj)
    },
    /** 下一步 */
    next() {
      const form = this.dialog.step === 0 ? 'formStep1' : 'formStep2'
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.dialog.step++
        }
      })
    },
    /** 上一步 */
    previous() {
      this.dialog.step--
    },

    /** 提交按钮 */
    submitForm: function () {
      //debugger
      this.$refs['formStep3'].validate((valid) => {
        if (valid) {
          let form = this.dialog.form
          // form.principalGuid = form.principalGuids[form.principalGuids.length - 1]
          if (form.cardLongTerm) {
            form.cardEndTime = '9999-12-31'
          }

          if (form.guid != undefined) {
            updateEnterprise(form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess(res.data.message)
                this.dialog.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          } else {
            this
            addEnterprise(form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess(res.data.message)
                this.dialog.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          }
        }
      })
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除名称为"' + row.name + '"的数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(function () {
          let data = {
            guid: row.guid
          }
          return delEnterprise(data)
        })
        .then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
          }
        })
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams
      this.$confirm('是否确认导出所有角色数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(function () {
          return exportRole(queryParams)
        })
        .then((res) => {
          if (res.data.success) {
            this.$download(res.msg)
          } else {
            this.$msgError(res.data.message)
          }
        })
    }
  }
}
</script>
<style scoped>
.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-btn {
  height: 60px;
  width: 60px;
  color: white;
  font-size: 12px;
  text-align: center;
  line-height: 60px;
  border-radius: 50px;
  opacity: 0.8;
  display: inline-block;
  cursor: pointer;
}

.circle-red-bg {
  background: #ff543d;
}

.circle-grey-bg {
  background: #909399;
}

.hrline {
  height: 2px;
  padding: 0 5px;
  opacity: 0.8;
  border: none;
}

.dialog-form {
  margin: 20px;
}

.tip {
  font-size: 12px;
  color: #c7cbd2;
  margin-top: 0;
}
</style>
